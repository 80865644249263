import React, { useState, useEffect } from "react";
import { motion, useIsPresent } from "framer-motion";
import { useParams } from "react-router";
import "../styles/orderDetails.css";
import { getOrder } from "../helpers/firestore";
import OrderDetailsCard from "../components/orderDetailsCard";
import { useNavigate } from "react-router";

export default function OrderDetailsPage() {
  const { id } = useParams();
  const isPresent = useIsPresent();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      getOrder(id).then((data) => {
        if (data) {
          setOrder(data);
          setLoading(false);
        } else {
          navigate("/404");
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className="detailsPage">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
        className="detailsPageContent"
      >
        <h1>Order {id}</h1>{" "}
        {order && order.status && (
          <h2>
            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
          </h2>
        )}
        {loading && <div className="orderDetailsCard loading"></div>}
        {order && <OrderDetailsCard order={order} />}
      </motion.div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
}
