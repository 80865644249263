import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    margin: 20,
  },
  row: {
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#ccc",
    alignItems: "center",
    height: 24,
    fontSize: 10,
  },

  th: {
    fontWeight: "bold",
  },

  cell: {
    fontSize: 10,
    paddingRight: 10,
  },

  cell1: {
    fontSize: 12,
    paddingRight: 10,

    width: "55%",
  },

  cell2: {
    fontSize: 12,
    width: "15%",
  },
  cell3: {
    fontSize: 12,
    width: "85%",
  },
});

const createItemSummary = (items) => {
  const itemsSummary = [];
  for (let i = 0; i < items.length; i++) {
    const existingItem = itemsSummary.find(
      (item) => item.product.id === items[i].product.id
    );
    if (existingItem) {
      console.log(existingItem);
      existingItem.quantity += items[i].quantity;
    } else {
      console.log(items[i]);
      itemsSummary.push({ ...items[i] });
    }
  }
  console.log(itemsSummary);
  return itemsSummary;
};

const OrderPDF = ({ order }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            marginTop: 20,
          }}
        >
          <Image
            src="https://images.squarespace-cdn.com/content/5ada446c70e80229f1aec2d9/f468711a-5d86-403b-889e-edf582dc903c/BlueNewYear.png?content-type=image%2Fpng"
            style={{ width: 50, marginBottom: 10 }}
          />
          <Text
            style={{
              fontSize: 18,
              fontWeight: 800,
            }}
          >
            NAIMUN Merchandise Invoice{" "}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            Generated on{" "}
            {new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Text>
        </View>
        <View
          style={{
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            Order #: {order.id}
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontWeight: "bold",
            }}
          >
            {order.email}
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontWeight: "bold",
            }}
          >
            Order Placed:{" "}
            {new Date(order.date.seconds * 1000).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Text>
        </View>

        <View
          style={{
            width: "80%",
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: "bold",
              marginBottom: 10,
            }}
          >
            Order Summary
          </Text>
          <View style={styles.row}>
            <Text style={styles.cell2}>Item</Text>
            <Text style={styles.cell2}>Qty</Text>
            <Text style={styles.cell2}>Price</Text>
            <Text style={styles.cell2}>Total</Text>
          </View>
          {createItemSummary(order.items).map((item) => {
            console.log(item);
            return (
              <View style={styles.row} key={item.product.id}>
                <Text style={styles.cell2}>{item.product.name}</Text>
                <Text style={styles.cell2}>{item.quantity}</Text>
                <Text style={styles.cell2}>${item.product.price}</Text>
                <Text style={styles.cell2}>
                  ${(item.product.price * item.quantity).toFixed(2)}
                </Text>
              </View>
            );
          })}
        </View>
        <View
          style={{
            marginTop: 30,
            width: "80%",
          }}
        >
          <View style={styles.row}>
            <Text style={styles.cell3}>Amount Due</Text>
            <Text
              style={{
                width: "15%",
                fontSize: 16,
              }}
            >
              ${order.total.toFixed(2)}
            </Text>
          </View>
        </View>
        <Text
          style={{
            fontSize: 10,
            marginTop: 20,
            width: "80%",
          }}
        >
          Please make all checks payable to: “Georgetown International Relations
          Association, Inc.”
        </Text>
      </Page>
    </Document>
  );
};

export default OrderPDF;
