import React from "react";
import { motion } from "framer-motion";
import "../styles/orderDetails.css";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderPDF from "../components/orderPDF";

export default function OrderDetailsCard({ order }) {
  const date = new Date(order.date.seconds * 1000);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const quantity = order.items.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <motion.div
      className="orderDetailsCard"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="orderCardDetailsBody">
        <div className="orderCardHeader">
          <div className="orderCardDetails">
            Order placed {date.toLocaleDateString("en-US", dateOptions)}
          </div>
        </div>
      </div>
      {order.items.map((item) => (
        <div className="orderCardLineItem" key={item.product.id}>
          <div className="orderDetailsCardImage">
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${item.product.image}_200x200?alt=media`}
              alt={item.product.name}
            />
          </div>
          <div className="orderDetailsCardText">
            {item.size != "none" && item.size}
          </div>
          <div className="orderDetailsCardText">x{item.quantity}</div>
          <div
            className="orderDetailsCardText orderDetailsHideable"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            ${Number.parseFloat(item.quantity * item.product.price).toFixed(2)}
          </div>
        </div>
      ))}
      <div className="orderCardTotalLine">
        <div className="orderDetailsCardText orderCardLineTitle">Total</div>
        <div className="orderDetailsCardText">{"    "} </div>
        <div className="orderDetailsCardText">{quantity}</div>
        <div className="orderDetailsCardText" style={{ fontWeight: "bold" }}>
          ${order.total.toFixed(2)}
        </div>
      </div>
      {order && (order.status === "paid" || order.status === "pending") && (
        <PDFDownloadLink
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
            backgroundColor: "#46a0d7",
            color: "white",
            padding: "10px 20px",
            borderRadius: "3px",
            gap: "10px",
            fontWeight: "bold",
          }}
          document={<OrderPDF order={order} />}
          fileName={`order-${order.id}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download Invoice"
          }
        </PDFDownloadLink>
      )}
    </motion.div>
  );
}
