import React, { useState } from "react";
import {
  AddressElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { IoCheckmarkCircle } from "react-icons/io5";
import "../styles/checkout.css";
import {
  logAddPaymentInfo,
  logAddShippingInfo,
  logPurchase,
} from "../helpers/analytics";
import { TailSpin } from "react-loader-spinner";
import { motion, AnimatePresence } from "framer-motion";

export default function CheckoutForm({ finalTotal, couponCode, items }) {
  const stripe = useStripe();
  const elements = useElements();
  const [organization, setOrganization] = useState("");
  const [drawerOpen, setDrawerOpen] = useState("details");
  const [org, setOrg] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const paymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
    business: { name: "NAIMUN LXII" },
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    var addressElement = elements.getElement("address");
    addressElement.getValue().then((result) => {
      if (result.complete) {
        setDrawerOpen("payment");
        logAddShippingInfo(items.items, finalTotal.finalTotal);
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    elements.submit().then(function (result) {
      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        setErrorMessage(null);
      }
    });

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const response = await fetch(
      "https://naimun-payment-server-970946723811.us-east4.run.app/create-payment-intent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ total: finalTotal }),
      }
    );

    const { clientSecret } = await response.json();

    stripe
      .confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.REACT_APP_PUBLIC_URL}/success`,
        },
      })
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message);
        } else {
          setErrorMessage(null);
          logPurchase(items.items, finalTotal.finalTotal);
        }
      });
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="checkoutDetails ">
        <div
          className={`${
            drawerOpen !== "details" ? "closedDrawer" : "openDrawer"
          } checkoutDrawer`}
          style={drawerOpen !== "details" ? { cursor: "pointer" } : {}}
          onClick={() => {
            if (drawerOpen !== "details") {
              setDrawerOpen("details");
            }
          }}
        >
          {drawerOpen !== "details" && (
            <div className="checkoutHeader" style={{ margin: 0 }}>
              Details
              <IoCheckmarkCircle
                color="#78c5f4"
                fontSize={"1.5rem"}
                style={{ marginLeft: "10px" }}
              />
            </div>
          )}
          <div className={drawerOpen !== "details" ? "disabled" : ""}>
            <div className="drawerHeader">Details</div>
            <div className="organizationSelect">
              <label htmlFor="agreement" className="checkoutOrgContainer">
                I understand that I must pick up my order at the conference, and
                that it will not be shipped to me.
                <input
                  type="checkbox"
                  id="agreement"
                  value={agreement}
                  onChange={() => setAgreement(!agreement)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {agreement && (
              <>
                <div className="organizationSelect">
                  <label
                    htmlFor="organization"
                    className="checkoutOrgContainer"
                  >
                    I'm ordering for a group
                    <input
                      type="checkbox"
                      id="organization"
                      value={org}
                      onChange={() => setOrg(!org)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkoutHeader">Billing Address</div>
                <AnimatePresence>
                  {org && (
                    <motion.div
                      className="checkoutInput"
                      key={"checkoutOrg"}
                      initial={{ opacity: 0, scaleY: 0, height: 0 }}
                      animate={{ opacity: 1, scaleY: 1, height: "auto" }}
                      exit={{ scaleY: 0, originY: 0, height: 0 }}
                    >
                      <div className="checkoutFormlabel">Organization</div>
                      <input
                        type="text"
                        name="organization"
                        placeholder=""
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
                <AddressElement
                  id="address-element"
                  options={{
                    mode: "billing",
                    fields: {
                      phone: "always",
                    },
                    validation: {
                      phone: {
                        required: "always",
                      },
                    },
                  }}
                />
                <div className="checkoutButton">
                  <button
                    onClick={(e) => {
                      handleAddressSubmit(e);
                    }}
                  >
                    Continue to Payment
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={`${
            drawerOpen !== "payment" ? "closedDrawer" : "openDrawer"
          } checkoutDrawer`}
        >
          {drawerOpen !== "payment" ? (
            <>
              <div className="checkoutHeader" style={{ marginTop: 0 }}>
                Payment
              </div>
              <div className="checkoutPaymentThumbnails">
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://www.ikea.com/global/assets/logos/external-payment-providers/visa.svg"
                  alt="Visa"
                />
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://www.ikea.com/global/assets/logos/external-payment-providers/master-card.svg"
                  alt="Mastercard"
                />
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://www.ikea.com/global/assets/logos/external-payment-providers/discover.svg"
                  alt="Discover"
                ></img>
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://www.ikea.com/global/assets/logos/external-payment-providers/american-express.svg"
                  alt="American Express"
                ></img>
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://www.ikea.com/global/assets/logos/external-payment-providers/diners-club.svg"
                  alt="Diners"
                ></img>
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://www.ikea.com/global/assets/logos/external-payment-providers/jcb.svg"
                  alt="JCB"
                ></img>
                <img
                  className="checkoutPaymentThumbnail grayscale"
                  src="https://storage.googleapis.com/images-ikea-payments/card-brands/UnionPay.png"
                  alt="UnionPay"
                ></img>
              </div>
            </>
          ) : (
            <>
              <div className="drawerHeader">Payment Information</div>
              <PaymentElement
                id="pamyent-element"
                options={paymentElementOptions}
              />
              <div className="checkoutButton">
                <button
                  type="submit"
                  onClick={() => {
                    logAddPaymentInfo(items.items, finalTotal.finalTotal);
                  }}
                >
                  Confirm Payment
                </button>
              </div>
              <AnimatePresence>
                {loading && (
                  <motion.div
                    className="checkoutLoading"
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <TailSpin color="#46a0d7" height={50} width={50} />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          )}
          <div className="checkoutError">{errorMessage}</div>
        </div>
      </div>
    </form>
  );
}
