import React from "react";

export default function RedirectModal(close) {
  console.log("redirect modal");
  return (
    <div className="adblockPopup">
      <div className="adblockPopupText" style={{ width: "80%" }}>
        <h1>Looking for our app?</h1>
        <p>
          If you're looking for the NAIMUN conference app, click the "Get App"
          button below. If you're looking for our merch store, click "Continue
          to Merch."
        </p>
        <button
          className="adblockPopupButton adblockPopupButtonSecondary"
          onClick={() => {
            window.location.href =
              "https://lxii-misc-970946723811.us-east4.run.app/app";
          }}
        >
          Get App
        </button>
        <button
          className="adblockPopupButton"
          onClick={() => {
            close(false);
          }}
        >
          Continue to Merch
        </button>
      </div>
    </div>
  );
}
