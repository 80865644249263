import React, { useEffect, useState } from "react";
import { motion, useIsPresent } from "framer-motion";
import "../styles/code.css";
import { useParams } from "react-router";
import { QRCode } from "react-qrcode-logo";

export default function CodePage() {
  const { id } = useParams();
  const isPresent = useIsPresent();

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  function downloadPass(id) {
    try {
      fetch("https://lxii-misc-970946723811.us-east4.run.app/pass", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ orderNumber: id }),
      }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "naimun-pass.pkpass";
          a.click();
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="codePage">
      <QRCode
        value={process.env.REACT_APP_PUBLIC_URL + `/orders/${id}`}
        size={300}
        bgColor="#f7fbfd"
        fgColor="#46a0d7"
        removeQrCodeBehindLogo={true}
        logoImage="https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/NAIMUN%20LXII%20Logo%20Transparent%20Background.png?alt=media&token=57f51a6d-0e48-40b4-a677-eff5c437d92c"
        logoWidth={80}
        logoHeight={67}
        logoOpacity={1}
        qrStyle="dots"
        eyeColor="#46a0d7"
        quietZone={10}
        id="qrCode"
      />
      <div className="codePageDescription">
        Present this code at the merch desk to pick up your order!
      </div>
      <div className="codePageActions">
        {iOS() && (
          <img
            src="/wallet.png"
            alt="Add to Wallet"
            onClick={() => downloadPass(id)}
          />
        )}
        <button id="printButton" onClick={() => window.print()}>
          Print
        </button>
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
}
