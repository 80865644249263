import React from "react";
import "../styles/about.css";
import { motion, useIsPresent } from "framer-motion";

export const SizingPage = () => {
  const isPresent = useIsPresent();
  return (
    <div className="aboutPage">
      <h1>Sizing Guide</h1>
      <div className="sizingSection">
        <h2>T-Shirts</h2>
        <table>
          <tr>
            <th>Size</th>
            <th>Width (in)</th>
            <th>Length (in)</th>
            <th>Sleeve center back (in)</th>
          </tr>
          <tr>
            <td>S</td>
            <td>18</td>
            <td>28</td>
            <td>15.25</td>
          </tr>
          <tr>
            <td>M</td>
            <td>20</td>
            <td>29</td>
            <td>16.5</td>
          </tr>
          <tr>
            <td>L</td>
            <td>22</td>
            <td>30</td>
            <td>18</td>
          </tr>
          <tr>
            <td>XL</td>
            <td>24</td>
            <td>31</td>
            <td>19.5</td>
          </tr>
        </table>
      </div>
      <div className="sizingSection">
        <h2>Sweatpants</h2>
        <table>
          <tr>
            <th>Size</th>
            <th>Inseam (in)</th>
            <th>Relaxed Waist (in)</th>
          </tr>
          <tr>
            <td>S</td>
            <td>31.75</td>
            <td>13</td>
          </tr>
          <tr>
            <td>M</td>
            <td>32.25</td>
            <td>14</td>
          </tr>
          <tr>
            <td>L</td>
            <td>32.75</td>
            <td>15</td>
          </tr>
          <tr>
            <td>XL</td>
            <td>33.25</td>
            <td>16</td>
          </tr>
          <tr>
            <td>2XL</td>
            <td>33.75</td>
            <td>16</td>
          </tr>
        </table>
      </div>
      <div className="sizingSection">
        <h2>Crewnecks</h2>
        <table>
          <tr>
            <th>Size</th>
            <th>Body Width (in)</th>
            <th>Body Length (in)</th>
            <th>Sleeve Length (in)</th>
          </tr>
          <tr>
            <td>S</td>
            <td>20</td>
            <td>26.5</td>
            <td>24.38</td>
          </tr>
          <tr>
            <td>M</td>
            <td>22</td>
            <td>27.5</td>
            <td>24.63</td>
          </tr>
          <tr>
            <td>L</td>
            <td>24</td>
            <td>28.5</td>
            <td>24.25</td>
          </tr>
          <tr>
            <td>XL</td>
            <td>26</td>
            <td>29.5</td>
            <td>24</td>
          </tr>
        </table>
      </div>
      <div className="sizingSection">
        <h2>Tote Bag</h2>
        <table>
          <tr>
            <th>Size</th>
          </tr>
          <tr>
            <td>14 1/2" W x 15 1/2" D</td>
          </tr>
        </table>
      </div>

      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
};
