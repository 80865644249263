import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { motion, useIsPresent } from "framer-motion";
import "../styles/product.css";
import { getProduct } from "../helpers/firestore";
import { CartContext } from "../helpers/CartContext";
import { LineWave } from "react-loader-spinner";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { viewItem } from "../helpers/analytics";
import { useNavigate } from "react-router-dom";
import { logCartAdd } from "../helpers/analytics";
import { InnerImageZoom } from "react-inner-image-zoom";
import { Helmet } from "react-helmet";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

export default function ProductPage() {
  const { id } = useParams();
  const { addToCart } = React.useContext(CartContext);
  const isPresent = useIsPresent();
  const [product, setProduct] = useState(null);
  const [imageSelected, setImageSelected] = useState("");
  const [sizeSelected, setSizeSelected] = useState("none");
  const [sizeError, setSizeError] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [imagesLength, setImagesLength] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getProduct(id).then((product) => {
      setProduct(product);
      setImageSelected(product.images[0]);
      setImagesLength(product.images.length);
    });
  }, [id]);

  useEffect(() => {
    //trigger analytics event for viewing item
    if (product) {
      viewItem(product);
    }
  }, [product]);

  const handleAdd = () => {
    if (product.sizes && product.sizes.length > 0 && sizeSelected === "none") {
      setSizeError(true);
      return;
    }
    console.log(product);
    addToCart(product, sizeSelected);
    logCartAdd(product);
  };

  return (
    <div className="productPage">
      {product ? (
        <>
          <Helmet>
            <meta
              property="og:image"
              content={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${imageSelected}_200x200?alt=media`}
            />
            <meta property="og:title" content={product.name} />
          </Helmet>
          <div className="imageContainer">
            <div className="imageCarousel">
              {product.images.map((image, index) => (
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${image}_200x200?alt=media`}
                  alt={product.name}
                  key={index}
                  onClick={() => setImageSelected(image)}
                  className={image === imageSelected ? "carouselSelected" : ""}
                />
              ))}
            </div>
            <div className="productImage">
              <InnerImageZoom
                src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Ffull%2F${imageSelected}_1728x2160?alt=media`}
                zoomSrc={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Ffull%2F${imageSelected}_1728x2160?alt=media`}
                alt={product.name}
                zoomType={"hover"}
                zoomPreload={true}
                hideHint={true}
                zoomScale={0.6}
                id="productImage"
                style={{
                  backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${imageSelected}_200x200?alt=media") no-repeat center center`,
                }}
              />
              {imagesLength > 1 && (
                <div className="imageSelector">
                  <div
                    className="imageSelectorIcon"
                    onClick={() => {
                      setImageSelected(
                        product.images[
                          (imageIndex - 1 + imagesLength) % imagesLength
                        ]
                      );
                      setImageIndex(
                        (imageIndex - 1 + imagesLength) % imagesLength
                      );
                    }}
                  >
                    <IoChevronBack />
                  </div>
                  <div
                    className="imageSelectorIcon"
                    onClick={() => {
                      setImageSelected(
                        product.images[(imageIndex + 1) % imagesLength]
                      );
                      setImageIndex((imageIndex + 1) % imagesLength);
                    }}
                  >
                    <IoChevronForward />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="productDetails">
            <div className="productPageTitle">{product.name} </div>
            <div className="productPagePrice">${product.price}</div>
            <div className="productPageDescription">{product.description}</div>
            {product.sizes && product.sizes.length > 0 && (
              <div className="productPageSize">
                <div className="productPageSizeTitle">
                  Choose a size -{" "}
                  <span
                    className="sizingGuideLink"
                    onClick={() => {
                      navigate("/sizing");
                    }}
                  >
                    Sizing Guide
                  </span>
                </div>
                <div className="productPageSizeOptions">
                  {product.sizes.map((size) => (
                    <div
                      className={`productPageSizeOption ${
                        size === sizeSelected && "productPageSizeSelected"
                      }`}
                      key={size}
                      onClick={() => setSizeSelected(size)}
                    >
                      {size}
                    </div>
                  ))}
                </div>
                {sizeError && (
                  <div className="productPageSizeError">
                    Please select a size
                  </div>
                )}
              </div>
            )}
            <button onClick={() => handleAdd()}>Add to Cart</button>
          </div>
        </>
      ) : (
        <LineWave color="#46a0d7" />
      )}
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
}
